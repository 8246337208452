.dcs__spotlightarticle {
display: flex;
align-items: center;
justify-content: center;
margin-top: 1.5rem;
}

.dcs__spotlightarticle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.dcs__spotlightarticle-container img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: 50% 0;
}

.dcs__spotlightarticle-container_content {
    display: flex;
    flex-direction: column;
    
    font-family: var(--font-family);
    color: var(--c-text);
}

.dcs__spotlightarticle-container_content h5 {
    font-weight: 500;
    align-self: flex-end;
    position: absolute;
    margin-top: 12px;
}
.dcs__spotlightarticle-container_content-line1 {
    height: 1px;
    width: 100%;
    background: var(--c-text);
    display: none;
}
.dcs__spotlightarticle-container_content a {
    text-decoration: none;
    color: var(--c-text);
}
.dcs__spotlightarticle-container_content a:hover {
    opacity: .9;
}
.dcs__spotlightarticle-container_content a:active {
    opacity: .7;
}
.dcs__spotlightarticle-container_content h2 {
    width: 75%;
    align-self: flex-start;
}
.dcs__spotlightarticle-container_content-line2 {
    display: block;
    height: 1px;
    width: 100%;
    background: var(--c-text);
    margin: .5rem 0 .5em 0;
}
.dcs__spotlightarticle-container_content p {
    text-align: justify;
}

@media screen and (min-width: 650px) {
    .dcs__spotlightarticle-container {
        width: 80%;
    }
}

@media screen and (min-width: 750px) {
    .dcs__spotlightarticle-container {
        flex-direction: row;
        width: 80%;
        align-items: flex-start;
        gap: .5rem;

    }

    .dcs__spotlightarticle-container img {
        flex: 1.5;
        height: 300px;
    }
    .dcs__spotlightarticle-container_content {
        flex: 1;
    }
    .dcs__spotlightarticle-container_content h5 {
        position: relative;
        align-self: flex-start;
        margin-top: 0;
    }
    .dcs__spotlightarticle-container_content-line1 {
        display: block;
        margin: .5rem 0 .5em 0;
    }
    .dcs__spotlightarticle-container_content h2 {
        width: 100%;
        font-size: 2.5rem;
        line-height: 2.7rem;
    }
    .dcs__spotlightarticle-container_content p {
        line-height: 20px;
        align-items: flex-end;
    }
}

@media screen and (min-width: 1050px) {
    .dcs__spotlightarticle-container img {
        flex: 1.7;
        height: 400px;
    }
    .dcs__spotlightarticle-container_content h5 {
        font-size: 1.25rem;
    }

    .dcs__spotlightarticle-container_content h2 {
        font-size: 3.5rem;
        line-height: 3.8rem;
    }
    .dcs__spotlightarticle-container_content p {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}

@media screen and (min-width: 1600px) {
    .dcs__spotlightarticle-container img {
        flex: 1.7;
        height: 500px;
    }
    .dcs__spotlightarticle-container_content h5 {
        font-size: 1.5rem;
    }

    .dcs__spotlightarticle-container_content h2 {
        font-size: 4.3rem;
        line-height: 4.5rem;
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 1800px) {
    .dcs__spotlightarticle-container_content p {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}