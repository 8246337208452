.dcs__testimonialsection {
    margin: 80px 0 200px 0;
    display: flex;
    flex-direction: column;

    scroll-snap-align: center;
    justify-items: center;
    
}

.dcs__testimonialsection h1 {
    justify-self: center;
    font-family: var(--font-family-headings);
    color: var(--c-headings);
    font-size: 4rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 100px;
    letter-spacing: 3.28px;

}

.dcs__testimonialsection-container {
    scale: 1.3;
    width: 80%;
    margin-left: 10%;
}


@media screen and (max-width: 800px) {
    .dcs__testimonialsection {
        margin-bottom: 150px;
    }
    .dcs__testimonialsection h1 {
        font-size: 3rem;
    }
    .dcs__testimonialsection-container {
        scale: 1.2;
    }
}


@media screen and (max-width: 500px) {
    .dcs__testimonialsection h1 {
        font-size: 2.5rem;
    }
    .dcs__testimonialsection-container {
        scale: 1;
    }
}