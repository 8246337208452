.dcs__landing-section {
    width: 100%;
    height: 120vh;
    scroll-snap-align: start;
}

.dcs__landing-section_container {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    position: relative;
   
}

.dcs__landing-section_container-vector {
    /* backdrop-filter: blur(20px); */
    position: absolute;
    z-index: 1;
    width: 60%;
    left: 0;
    height: 120vh;  
    background-color: rgba(0,0,0,.8);
    background:conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 0, 0) 20%, rgba(0, 0, 0, 0.54) 21%, rgba(0, 0, 0, 0.51) 79%, rgba(197, 108, 197, 0) 80%);
}

.dcs__landing-section_container-blur {
    backdrop-filter: blur(12px);
    background-color: rgba(0,0,0,.5);
    
    height: 165vh;
    z-index: 1;
    width: 150%;
    left: -60%;
    transform: rotate(75deg);
    position: absolute;
}

.dcs__landing-section_container-heroimg {
    width: 100%;
    height: 120vh;
    object-fit: cover;
    object-position: 100% 0;
    filter: saturate(60%);
    position: relative;
}

.dcs__landing-section_container-title {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 15%;
    top: 22vh;
    scale: 1.4;

    z-index: 2;
}
.dcs__landing-section_container-title h1 {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 2rem;
    color: var(--c-white-text-headings);
    margin-left: 10px;
}

.dcs__landing-section_container-title img {
    width: 275px;
    height: 100%;
    object-fit: contain;
    position: relative;
}

.dcs__landing-section_container-title p {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: -1px;
    color: var(--c-white-text-headings);
    margin-left: 10px;
    margin-top: 5px;

}
.dcs__landing-section_container-title span {
    font-size: 1.85rem;
}

.dcs__landing-section .dcs__booknowbutton {
    right: 60%;
    bottom: 27vh;
}




@media screen and (max-width: 1400px) {
    .dcs__landing-section_container-title {
        scale: 1.1;
        left: 10%;
        bottom: 60vh;
    }
    .dcs__landing-section .dcs__booknowbutton {
        right: 53%;
        /* bottom: 20vh; */
    }
    .dcs__landing-section_container-blur {
        left: -65%;
    }
    
}

@media screen and (max-width: 840px) {
    .dcs__landing-section_container-title {
        scale: .8;
        left: 5%;
        top: 35vh;
    }
    .dcs__landing-section .dcs__booknowbutton {
        right: 35%;
        /* bottom: 50vh; */
        scale: .8;
    }
    .dcs__landing-section_container-blur {
        left: -80%;
    }
}

@media screen and (max-width: 615px) {
    .dcs__landing-section_container-title {
        justify-self: center;
        justify-content: center;
        left: auto;
        top: 15vh;
    }
    .dcs__landing-section .dcs__booknowbutton {
        align-items: center;
        justify-self: center;
        right: auto;
        
        
    }
    
    .dcs__landing-section {
        justify-content: center;
        justify-items: center;
    }
    .dcs__landing-section_container {
        justify-content: center;
        
        left: auto;
    }
    .dcs__landing-section_container-blur {
        backdrop-filter: none;
        z-index: 1;
        height: 120vh;
        width: 100%;
        left: 0;
        bottom: 0;
        transform: rotate(0deg);
        position: absolute;
        background-color: rgba(0,0,0,.3);
    }
}


@media screen and (max-width: 450px) {
    .dcs__landing-section .dcs__booknowbutton h3 {
        text-align: center;
    }
}