.dcs__articlepage .dcs__navbar {
    color: var(--c-text);
    position: relative;
}

.dcs__articlepage .dcs__navbar-links ul {
    background-color: transparent;
} 
.dcs__articlepage .dcs__navbar-links_menu ul {
    background-color: black;
    
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.dcs__articlepage .dcs__navbar-links_menu svg {
    fill: #fff;
}

.dcs__articlepage .dcs__navbar-links_nav li a {
    color: var(--c-text);
}

.dcs__articlepage .dcs__navbar-links_nav a:hover {
    border-bottom: 2px solid var(--c-text)
}

.dcs__articlepage .dcs__navbar-links_nav a:active {
    opacity: .8;
}

.dcs__articlepage {
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 2rem;
}

.dcs__articlepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.dcs__articlepage-container img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: 50% 0%;
}

.dcs__articlepage-container_content {
    display: flex;
    flex-direction: column;
    
    font-family: var(--font-family);
    color: var(--c-text);
}

.dcs__articlepage-container_content h5 {
    font-weight: 500;
    align-self: flex-end;
    margin-top: 12px;
}
.dcs__articlepage-container h2 {
    width: 75%;
    align-self: flex-start;
    font-family: var(--font-family-headings);
    color: var(--c-text);
    font-weight: 400;
    letter-spacing: 1.4px;
    margin-top: 3rem;

}
.dcs__articlepage-container_content-line {
    display: block;
    height: 1px;
    width: 100%;
    background: var(--c-text);
    margin: .5rem 0 .5em 0;
}
.dcs__articlepage-container_content p {
    text-align: justify;
    margin-bottom: 2rem;
}

@media screen and (min-width: 650px) {
    .dcs__articlepage-container {
        width: 80%;
    }
    .dcs__articlepage-container h2 {
        font-size: 2.5rem;
        margin-top: 0;
    }
    .dcs__articlepage-container img {
        height: 350px;
    }
    .dcs__articlepage-container_content h5 {
        font-size: 1rem;
    }
}

@media screen and (min-width: 750px) {
    .dcs__articlepage-container {
        width: 80%;
        gap: .5rem;

    }

    .dcs__articlepage-container img {
        height: 500px;
        
    }
    .dcs__articlepage-container_content h5 {
        position: relative;
        margin-top: 0;
    }
    .dcs__articlepage-container_content-line1 {
        display: block;
        margin: .5rem 0 .5em 0;
    }
    .dcs__articlepage-container_content h2 {
        width: 100%;
        font-size: 2.5rem;
        line-height: 2.7rem;
    }
    .dcs__articlepage-container_content p {
        line-height: 20px;
        align-items: flex-end;
    }
}

@media screen and (min-width: 1050px) {
    .dcs__articlepage-container img {
        height: 600px;
    }
    .dcs__articlepage-container_content h5 {
        font-size: 1.25rem;
    }

    .dcs__articlepage-container_content h2 {
        font-size: 3.5rem;
        line-height: 3.8rem;
    }
    .dcs__articlepage-container_content p {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}

@media screen and (min-width: 1600px) {
    .dcs__articlepage-container img {
        height: 800px;
    }
    .dcs__articlepage-container_content h5 {
        font-size: 1.5rem;
    }

    .dcs__articlepage-container_content h2 {
        font-size: 4.3rem;
        line-height: 4.5rem;
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 1800px) {
    .dcs__articlepage-container_content p {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}