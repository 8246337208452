* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
}

body {
  background: url('../src/assets/background-image.jpg');
  background-attachment: fixed;

}