.dcs__socialsection {
    display: flex;
    width: 100%;   
    height: 300px; 
    align-items: center;
    justify-content: center;
    /* margin: 0% 0% 10% 0%; */
    padding: 100px 0 0 0 ;

}

.dcs__socialsection-container {
    display: flex;
    width: 80%;
    justify-content: space-around;

    height: fit-content;


}

.dcs__socialsection-container_icon {
    width: 100px;
    height: 100px;
    color: var(--c-text);
}
.dcs__socialsection-container_icon:active {
    opacity: .9;
}

@media screen and (max-width: 1050px) {
    .dcs__socialsection-container_icon {
        width: 65px;
        height: 65px;
    }
}

@media screen and (max-width: 775px) {
    .dcs__socialsection {
        height: 300px;
    }
    .dcs__socialsection-container_icon {
        width: 50px;
        height: 50px;
    }
}


@media screen and (max-width: 500px) {
    .dcs__socialsection {
        height: 200px;
    }
    .dcs__socialsection-container_icon {
        width: 40px;
        height: 40px;
    }
}
