.dcs__smallarticletile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
    
}

.dcs__smallarticletile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: flex-end;
    
    width: 320px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);

}


.dcs__smallarticletile-container:active {
    scale: .95;
}

.dcs__smallarticletile-container img {
    object-fit: cover;
    width: 100%;
    height: 320px;
    border-radius: 8px;
}

.dcs__smallarticletile-container_content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;    
    align-content: flex-end;
}

.dcs__smallarticletile-container_content-date {
    background: white;
    padding: 4px 16px;
    border-radius: 8px;
    margin-bottom: -.75rem;
    z-index: 1;
}

.dcs__smallarticletile-container_content-date p {
    color: var(--c-text);
    font-family: var(--font-family);
    font-size: .8em;    
}

.dcs__smallarticletile-container_content-title {
    background: rgba(217,217,217,.7);
    border-radius: 8px;
    width: 320px;
    padding: .8rem 2rem .5rem 2rem;
    text-align: center;
}

.dcs__smallarticletile-container_content-title h4 {
    color: black;
    font-family: var(--font-family);
    font-size: 1.25em;  
}


@media screen and (min-width: 700px) {
    .dcs__smallarticletile {
        scale: 1.2;
        margin-bottom: 7rem;
    }
}

@media screen and (min-width: 1000px) {
    .dcs__smallarticletile {
        scale: .9;
        margin-bottom: 0;
    }
    
    .dcs__smallarticletile-container_content-title
    .dcs__smallarticletile-container {
        width: 300px;
    }

    .dcs__smallarticletile-container img {
        height: 400px;
    }

}

@media screen and (min-width: 1550px) {
    .dcs__smallarticletile {
        scale: 1.1;
        margin-bottom: 0;
    }
    
    

    .dcs__smallarticletile-container img {
        height: 425px;
    }

}