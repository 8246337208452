.dcs__testimonial {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 10% 0 10%;

    font-family: var(--font-family);
    color: var(--c-text);

}

.dcs__testimonial-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10% 0 10%
}   


.dcs__testimonial-content img {
    width: 110px;
    height: 110px;
    border-radius: 75px;
    object-fit: cover;

    margin-bottom: 15px;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.dcs__testimonial-content span {
    font-size: 3rem;
    line-height: 1rem;
} 


.dcs__testimonial-details h4 {
    margin-top: .8rem;
} 
.dcs__testimonial-content p {
    text-align: center;
} 

.dcs__testimonial-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}


@media screen and (min-width: 850px) {
    .dcs__testimonial-content {
        flex-direction: row;
        
    }
    .dcs__testimonial-content img {
        align-self: center;
        width: 110px;
        margin-bottom: 0;
        
    }
        
    .dcs__testimonial-content p {
        width: 90%;
    }

    .dcs__testimonial-details {
        align-items: flex-start;
        margin-left: 145px;
        margin-top: -20px;
    }
}

@media screen and (min-width: 1000px) {
    .dcs__testimonial h1 {
        font-size: 3rem;
    }
    .dcs__testimonial-content p {
        font-size: 1.3em;
        margin-bottom: 1.5rem;
    }
}
@media screen and (min-width: 1500px) {
    .dcs__testimonial {
        scale: 1;
    }
    .dcs__testimonial h1 {
        font-size: 4rem;
    }
}