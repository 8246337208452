.dcs__bookingform {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    padding-top: 10rem;
    overflow-y: auto;
    
    background-color:rgba(0,0,0,.5); 
    backdrop-filter: blur(5px);
    z-index: 1000;
    display: flex;
    justify-content: center;
}

.dcs__bookingform-container {
    scale: .8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 90%;

    position: relative;
    top: -60px;

    color: white;
    font-family: var(--font-family);
    text-align: justify;
    

   
}
.dcs__bookingform-container svg {
    cursor: pointer;

    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
}

.dcs__bookingform-container img {
    width: 100px;
    height: 100px;
}

.dcs__bookingform-container h1 {
    font-size: 2rem;
    font-weight: 400;
    margin: .5rem 0 1rem 0;
}
.dcs__bookingform-container p {
    font-weight: 300;
    line-height: 1.5rem;
    font-size: 1.5rem;
    width: 90%;
    margin-left: 5%;
    align-self: flex-start;
    
}

/* Form Styles */

.dcs__bookingform-container_form {
    display: flex;
    flex-direction: column;
    gap: .4rem;
}


.dcs__bookingform-container_form input,
.dcs__bookingform-container_form textarea {
    align-self: center;
    border-radius: 5px;
    border: none;
    background-color: var(whitesmoke);
    font-family: var(--font-family);
    color: var(--c-text);
    font-size: 1.4rem;
    padding-left: .5rem;
    padding: 1rem 0 1rem .5rem;

    height: 3rem;
    width: 100%;
}
.dcs__bookingform-container_form input:focus,
.dcs__bookingform-container_form textarea:focus {
    outline: none;
    color: var(--c-text);
    font-weight: bolder;
}
.chooseFile {
    padding-bottom: 5rem;

}
.datepicker {
    margin-top: 2rem;
    justify-self: center;
}

.dcs__bookingform-container_form textarea {
    padding: 0rem 0 1rem .5rem;
    height: 10rem;
}

.dcs__bookingform-container_form button {
    scale: 1.2;
    align-self: center;
    border-style: none;
    margin-top: 3rem;
    border-radius: 50px;
    box-shadow: 1px 2px;
    font-family: var(--font-family);
    letter-spacing: 1px;

    font-size: 1.5rem;
    -webkit-text-fill-color: white;
    width: 180px;
    height: 40px;
    background-color: var(--c-red);
    cursor: pointer;

}

.dcs__bookingform-container_form button:hover {
    background: rgb(175, 8, 8);
}
.dcs__bookingform-container_form button:active {
    box-shadow: 1px 2px inset;
}

.dcs__bookingform-container_thankyou {
    text-align: center;
    color: white;
    margin-top: 5rem;
    font-weight: bolder;
}
.dcs__bookingform-container_thankyou p {
    line-height: 1.5em;
}

@media screen and (min-width: 650px) {
    .dcs__bookingform-container {
        width: 60%;
    }
    .dcs__bookingform-container img {
        width: 120px;
        height: 120px;
    }
    
    .dcs__bookingform-container h1 {
        font-size: 2.5rem;
    }
    .dcs__bookingform-container p {
        font-size: 1rem;
        line-height: 1.8rem;
    }
    .dcs__bookingform-container_thankyou p {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}

@media screen and (min-width: 1050px) {
    .dcs__bookingform-container {
        width: 40%;

    }
    .dcs__bookingform-container img {
        width: 140px;
        height: 140px;
    }
    
    .dcs__bookingform-container h1 {
        font-size: 3rem;
    }
    .dcs__bookingform-container p {
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
    .dcs__bookingform-container_thankyou p {
        font-size: 3rem;
        line-height: 3.5rem;
    }
}