.dcs__faqs {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    overflow-y: auto;

    background-color:rgba(0,0,0,.5); 
    backdrop-filter: blur(5px);
    z-index: 1000;
    display: flex;
    justify-content: center;
}

.dcs__faqs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 90%;

    position: relative;
    top: -60px;

    color: white;
    font-family: var(--font-family);
    text-align: justify;
    margin-bottom: 5rem;
}
.dcs__faqs-container svg {
    cursor: pointer;

    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
}

.dcs__faqs-container img {
    width: 100px;
    height: 100px;
}

.dcs__faqs-container h1 {
    font-size: 2rem;
    font-weight: 400;
    margin: 2rem 0 1rem 0;
}
.dcs__faqs-container h2 {
    font-size: 1.7rem;
    margin-bottom: .5rem;
    font-weight: 600;
    align-self: start;
    
}
.dcs__faqs-container p {
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.3rem;
    margin-bottom: 2rem;
    align-self: flex-end;
    text-align: end;
}

@media screen and (min-width: 650px) {
    .dcs__faqs-container {
        width: 60%;
    }
    .dcs__faqs-container img {
        width: 125px;
        height: 125px;
    }
    
    .dcs__faqs-container h1 {
        font-size: 3rem;
    }
    .dcs__faqs-container p {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

@media screen and (min-width: 1050px) {
    .dcs__faqs-container {
        width: 40%;
        scale: .8;

    }
    .dcs__faqs-container img {
        width: 150px;
        height: 150px;
    }
    
    .dcs__faqs-container h1 {
        font-size: 3rem;
    }
}