.dcs__aboutsection {
    width: 80%;
    margin: 5% 10% 0% 10%;
    scale: 1;
    scroll-snap-align: start;
    scroll-margin-top: 3rem;
   
}

.dcs__aboutsection-container {
    display: flex;
}

.dcs__aboutsection-container_image {
    display: flex;
    width: 100%;
    max-height: 700px;
    
}

.dcs__aboutsection-container_image-line {
    display: flex;
    height: 100%;
    width: 10px;
    background: var(--c-deep-red);
    margin: 0 10px 0 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.dcs__aboutsection-container_image-line2 {
    display: flex;
    height: 100%;
    width: 20px;
    background: var(--c-deep-red);
    margin: 0 5px 0 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.dcs__aboutsection-container_image img {
    object-fit: cover;
    width: 90%;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    
}

.dcs__aboutsection-container_content {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-left: 5%;
    width: 75%; 

    font-family: var(--font-family);
    color: var(--c-text);
    font-weight: 300;
    font-size: 1.5rem;
}
.dcs__aboutsection-container_content h2 {
    font-family: var(--font-family-headings);
    font-size: 4rem;
    letter-spacing: 7px;
    font-weight: 400;
}
.dcs__aboutsection-container_content h3 {
    color: var(--c-light-text);
    letter-spacing: 1px;
    font-weight: 300;
}
.dcs__aboutsection-container_content p {
    letter-spacing: 2px;
    margin-top: 1rem;
    text-align: justify;
    font-size: 1.25rem;
}

@media screen and (max-width: 1720px) {
    .dcs__aboutsection-container_content h2 {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .dcs__aboutsection-container_content h3 {
        font-size: 1.2rem;
    }
    .dcs__aboutsection-container_content p {
        font-size: 1rem;
    }
    .dcs__aboutsection-container_image {
        height: 600px;
    }
}

@media screen and (max-width: 1250px) {
    .dcs__aboutsection-container_content h2 {
        font-size: 2rem;
        line-height: 2.5rem;
    }
    .dcs__aboutsection-container_content h3 {
        font-size: 1.2rem;
    }
    .dcs__aboutsection-container_content p {
        letter-spacing: .5px;
        line-height: 1.1rem;
    }
    .dcs__aboutsection-container_image {
        height: 500px;
    }
}


@media screen and (max-width: 845px) {
    .dcs__aboutsection-container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .dcs__aboutsection-container_content {
        align-items: center;
        margin: 40px 0 30px 0;
        width: 100%;
    }
    .dcs__aboutsection-container_content h2 {
        justify-self: center;
        font-size: 2.5rem;
        line-height: 3rem;
        margin-bottom: 12px;
    }
    .dcs__aboutsection-container_content h3 {
        font-size: 1.25rem;
    }
    .dcs__aboutsection-container_content p {
        letter-spacing: 2px;
        line-height: 1.4rem;
    }
    .dcs__aboutsection-container_image {
        height: 400px;
    }
}

@media screen and (max-width: 500px) {

    .dcs__aboutsection-container_content h2 {
        justify-self: center;
        font-size: 2.2rem;
        line-height: 2rem;
    }
    .dcs__aboutsection-container_content h3 {
        font-size: 1rem;
        letter-spacing: 0;
    }

    .dcs__aboutsection-container_image {
        height: 326px;        
    }
}