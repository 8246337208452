.dcs__articleselectionpage .dcs__navbar {
    color: var(--c-text);
    position: relative;
}
.dcs__articleselectionpage .dcs__navbar-links ul {
    background-color: transparent;
}

.dcs__articleselectionpage .dcs__navbar-links_menu ul {
    background-color: black;
    
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.dcs__articleselectionpage .dcs__navbar-links_menu svg {
    fill: #fff;
}

.dcs__articleselectionpage .dcs__navbar-links_nav li a {
    color: var(--c-text);
}

.dcs__articleselectionpage .dcs__navbar-links_nav a:hover {
    border-bottom: 2px solid var(--c-text)
}

.dcs__articleselectionpage .dcs__navbar-links_nav a:active {
    opacity: .8;
}

.dcs__articleselectionpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dcs__articleselectionpage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dcs__articleselectionpage-container_heading {
    width: 100%;
}

.dcs__articleselectionpage-container_heading-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.dcs__articleselectionpage-container_heading-title h1 {
    font-family: var(--font-family-headings);
    font-size: 1.5rem;
    letter-spacing: 1.5px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 2.8rem;
    
}
.dcs__articleselectionpage-container_heading-title_line {
    width: 90%;
    height: 1px;
    background-color: var(--c-text);
}

@media screen and (min-width: 650px) {
    .dcs__articleselectionpage-container_heading-title h1 {
        font-size: 2.5rem;
    }
    .dcs__articleselectionpage-container_heading-title_line {
        width: 80%;
    }
    
}