.dcs__gallerysection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    scroll-snap-align: start;
    scroll-margin-top: 5rem;
    
}

.dcs__gallerysection h2 {
    text-align: center;

    font-size: 2.5rem;
    font-family: var(--font-family-headings);
    letter-spacing: 5.5px;
    font-weight: 400;
    color: var(--c-headings);
}

.dcs__gallerysection-line {
    width: 90%;
    height: 2px;
    background-color: var(--c-text);
    margin-top: 4px;
}

.largerline {
    height: 4px;
}

.dcs__gallerysection-component {
    display: flex;
    width: 90%;
    margin: 2rem 0 2rem 0;
}

.dcs__gallerysection-component .instagram-gallery {
    width: 100%;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
}

.dcs__gallerysection-component .instagram-item {
    height: 1fr;
}

.dcs__gallerysection-component .instagram-item img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    object-fit: cover;
    height: 100%;
    width: 100%;

    border-radius: 8px;

}

@media screen and (min-width: 650px) {
    .dcs__gallerysection h2 {
        font-size: 3rem;

    }
    .dcs__gallerysection-component .instagram-gallery {
        grid-template-columns: repeat(3, 1fr)
    }
}

@media screen and (min-width: 1050px) {
    .dcs__gallerysection h2 {
        font-size: 4rem;
    }
    .dcs__gallerysection-component .instagram-gallery {
        grid-template-columns: repeat(4,1fr)
    }
}
