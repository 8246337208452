.dcs__audiosamplesection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0 200px 0;
    height: fit-content;

    scroll-snap-align: center;
    scroll-margin-top: 5rem;
    
}

.dcs__audiosamplesection-container {
    display: flex;
    width: 80%;
}

.dcs__audiosamplesection-container_content {
    display: flex;
    flex-direction: column;
    padding: 0px 50px 0 25px;
    align-items: flex-end;
}
.dcs__audiosamplesection-container_content :nth-child(3) {
    justify-items: flex-end;
    align-items: center;
    top: 10px;
    flex-direction: row;
    position: relative;
}
.dcs__audiosamplesection-container_content h2 {
    align-self: flex-start;

    font-family: var(--font-family-headings);
    font-size: 4rem;
    line-height: 4.3rem;
    font-weight: 400;
    letter-spacing: 6.5px;
    color: var(--c-headings);
}

.dcs__audiosamplesection-container_content p {
    font-family: var(--font-family);
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 300;
    color: var(--c-text);
    text-align: justify;
    margin-top: 30px;
}

.dcs__audiosamplesection-container_audioplayer {
    display: flex;
    width: 50%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.dcs__audiosamplesection-container_content .dcs__booknowbutton h3 {
    padding-right: 10px;
    color: var(--c-text);
    font-weight: 500;
}
    

@media screen and (max-width: 1710px ) {
    .dcs__audiosamplesection-container_content :nth-child(3) {
        top: 25px;
    }
    .dcs__audiosamplesection-container_content p {
        line-height: 1.5rem;
    }
}

@media screen and (max-width: 1600px ) {
    .dcs__audiosamplesection-container_content p {
        line-height: 1.5rem;
    }
}
@media screen and (max-width: 1450px ) {
    .dcs__audiosamplesection-container_content h2 {
        font-size: 3rem;
        line-height: 3.5rem;
    }

    .dcs__audiosamplesection-container_content p {
        font-size: 1.2rem;
        line-height: 1.25rem;
    }
    .dcs__audiosamplesection-container_content :nth-child(3) {
        scale: .8;
        width: 110%;
        left: 20%;
    }
    .dcs__audiosamplesection-container_content .dcs__booknowbutton-container {
        width: fit-content;
    }
    .dcs__audiosamplesection-container_content .dcs__booknowbutton h3 {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1325px ) {
    .dcs__audiosamplesection-container_content h2 {
        font-size: 2.5rem;
        line-height: 2.8rem;
        letter-spacing: 3px;
    }
}

@media screen and (max-width: 1010px ) {
    .dcs__audiosamplesection-container_content p {
        line-height: 1.1rem;
    }
}

@media screen and (max-width: 984px ) {

    .dcs__audiosamplesection-container {
        flex-direction: column;
    }
    .dcs__audiosamplesection-container_content {
        justify-items: center;
        padding: 0;
        margin-bottom: -50px;
    }
    .dcs__audiosamplesection-container_content h2 {
        align-self: center;
    }
    .dcs__audiosamplesection-container_content p {
        line-height: 1.5rem;
    }
    .dcs__audiosamplesection-container_audioplayer {
        
        display: flex;
        justify-content:center;
        width: 100%;
        height: auto;
    }
    .dcs__audiosamplesection-container_content :nth-child(3) {
        align-self: center;
        top: 420px;
        width: auto;
        left: 0;
    }
}

@media screen and (max-width: 984px ) {
    .dcs__audiosamplesection-container_content :nth-child(3) {
        flex-direction: column;
    }
}