.dcs__navbar {
    z-index: 20;
    width: 100%;
    position: fixed;
    top: 0;
}
.reveal {
    transform: translateY(0px);
    transition: all .3s ease-in-out;
}
.hide {
    /* display: none; */
    transform: translateY(-150px);
    transition: all .3s ease-in-out;
}
.dcs__navbar-links {
    display: flex;
    margin: 0rem;
    justify-content: space-between;
}


.dcs__navbar-links_logo a {
    display: flex;
    width: 100px;
    height: 100px;
    margin: 1rem;    
}


.dcs__navbar-links_nav {
    display: flex;   
    width: 100%;
    justify-content: center;
    align-items: flex-start;    
}
.dcs__navbar-links ul {
    list-style: none;
    display: flex;
    align-self: flex-start;
    background-color: black;
    
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.dcs__navbar-links_nav li {
    align-self: center; 
    padding: 2rem;
    
}


.dcs__navbar-links_nav a:hover {
    border-bottom: 2px solid var(--c-white-text-headings)
}

.dcs__navbar-links_nav a:active {
    opacity: .8;
}

.dcs__navbar-links_nav li a {
    text-decoration: none;
    font-family: var(--font-family);
    font-size: 1.4rem;
    line-height: 1.8rem;
    border:2px solid transparent;
    color: var(--c-white-text-headings);
}

.dcs__navbar-links_menu {
    background-color: black;
    
    border-radius: 8px;
    justify-content: center;
    margin-top: 1.5rem;
    margin-right: 1.5rem;

    display: none;
    position: relative;
    z-index: 999;
     padding: 1rem;
     height: 59px;
}

.dcs__navbar-links_menu svg {
    cursor: pointer;
    z-index: 199;
   
}

 .dcs__navbar-links_menu-container_links ul {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    
    text-align: end;
    background: rgba(0,0,0);
    padding: 1rem;
    padding-top: 3.5rem;
   
    padding-bottom: .5rem;
    position: absolute;
    right: 0px;
    top: 1rem;
    margin-top: -2rem;
    min-width: 210px;
    border-radius: 8px;   
}
.dcs__navbar-links_menu-container_links li {
    margin-top: .4rem;
}
.dcs__navbar-links_menu-container_links li a {
    text-decoration: none;
    font-family: var(--font-family);
    font-size: 1.25rem;
    color: var(--c-white-text-headings);
}

.dcs__navbar-links_menu-container_links li a:hover {
    border-bottom: 2px solid var(--c-white-text-headings)
}

.dcs__navbar-links_menu-container_links li a:active {
    opacity: .8;
}

@media screen and (max-width: 1450px) {
    .dcs__navbar-links_nav li a {
        font-size: 1.2rem;
    }
}

 @media screen and (max-width: 1050px) {
    .dcs__navbar-links_nav {
        display:none;
    }
    .dcs__navbar-links_menu {
        display: flex;
    }
 }

 @media screen and (max-width: 650px) {
    .dcs__navbar-links_logo {
        width: 75px;
        height: 75px;
    }
 }

 @media screen and (max-width: 500px) {
    .dcs__navbar-links_logo {
        justify-self: center;
    }
 }