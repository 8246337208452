.dcs__testimonialcarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dcs__testimonialcarousel-navdots {
    display: flex;
    align-items: center;
    margin-top: 16px;
    
}

.dcs__testimonialcarousel-navdots_rightarrow svg:hover,
.dcs__testimonialcarousel-navdots_leftarrow svg:hover {
   cursor: pointer; 
   fill: var(--c-text);
   transition: all .3s ease-in;
   
}

.dcs__testimonialcarousel-navdots_dots {
    display: flex;
    margin: 0 5px 0 5px;
}

.dcs__testimonialcarousel-navdots_leftarrow svg,
.dcs__testimonialcarousel-navdots_rightarrow svg {
    width: 20px;
    height: 20px;
    fill: var(--c-light-text);
}
.dcs__testimonialcarousel-navdots_dots-single {
    margin: 0 5px 0 5px;
}
.dcs__testimonialcarousel-navdots_dots-single svg:hover {
    cursor: pointer; 
    fill: var(--c-text);
    transition: all .3s ease-in-out;
}

.dcs__testimonialcarousel-navdots_dots-single svg {
    width: 30px;
    height: 30px;
    fill: var(--c-light-text);
}

.selected svg {
    fill: var(--c-text);
    transition: all ease-in-out .3s ;
}

@media screen and (max-width: 1200px) {
    .dcs__testimonialcarousel-navdots_leftarrow svg,
    .dcs__testimonialcarousel-navdots_rightarrow svg {
        fill: transparent;
    }
}