.dcs__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;

    background-color: #0606067a;
    padding: 4rem 0 4rem 0;
}

.dcs__footer-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
}

.dcs__footer-container_links {
    margin-right: 5%;
    justify-content: baseline;
    height: 150px;
    padding: 25px 0 25px 0;
}

.dcs__footer-container_links ul {
    display: flex;
    flex-direction: column;
    height: 100%;

    justify-content: space-between;

    

}

.dcs__footer-container_links li {
    list-style: none;

    font-family: var(--font-family);
    color: white;
    cursor: pointer;
}



.dcs__footer-container_contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 0 25px 0;
    margin-left: 5%;
    height: 150px;
    
    font-family: var(--font-family);
    color: white;
    font-weight: 400;
}
.dcs__footer-container_contact a {
    color: white;
}
.dcs__footer-container_contact a:visited {
    color: rgb(224, 224, 224)
}

.dcs__footer-container_contact h4 {
    font-weight: 400;
}

.dcs__footer-container_contact p {
    font-size: 12px;
}


@media screen and (min-width: 750px) {
    .dcs__footer-container_links li {
        font-size: 1.5rem;
    }
    .dcs__footer-container_contact h4 {
        font-size: 1.5rem;
    }
    
    .dcs__footer-container_contact p {
        font-size: 1rem;
    }

    .dcs__footer-container_contact,
    .dcs__footer-container_links {
        height: 200px;
    }
    
}
