.dcs__articlesection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 10rem 0;

    scroll-snap-align: start;
    scroll-margin-top: 7rem;
    
}

.dcs__articlesection-content {
    display: flex; 
    align-items: baseline;
    position: relative;
    width: 80%;
    justify-content: center;
}

.dcs__articlesection-content a {
    position: absolute;
    right: 25px;
    align-self: center;
    text-decoration: underline;

    font-family: var(--font-family);
    color: var(--c-text);
    
    display: none;
    cursor: pointer;
}
.dcs__articlesection-content a:active {
    opacity: .8;
}

.dcs__articlesection h2 {

    font-family: var(--font-family-headings);
    font-size: 2.5rem;
    color: var(--c-headings);
    font-weight: 400;
    letter-spacing: 3.44px;

    text-align: center;
    width: auto;
    margin-bottom: 2rem;
}

.dcs__articlesection-container {
    display: flex;
    flex-direction: column;
}
.dcs__articlesection-seeallbutton {
    align-self: center;
    text-decoration: underline;

    font-family: var(--font-family);
    font-size: 1.25rem;
    margin-top: -1.75rem;
}

.dcs__articlesection-seeallbutton a {
    color: var(--c-text);
    
}

@media screen and (min-width: 700px) {
    .dcs__articlesection h2 {
        width: auto;
        margin-bottom: 5rem;
    }
    .dcs__articlesection-seeallbutton {
        align-self: center;
        text-decoration: underline;
    
        font-family: var(--font-family);
        color: var(--c-text);
        font-size: 1.25rem;
        margin-top: -3rem;
    }
    
}

@media screen and (min-width: 1000px) {
    .dcs__articlesection-seeallbutton {
        display: none;
    }
    .dcs__articlesection-content a {
        display: flex;
    }

    .dcs__articlesection-container {
        width: 90%;
        flex-direction: row;
        justify-content: space-around;
    }
    .dcs__articlesection h2 {
        margin-bottom: 3rem;
        font-size: 3rem;
    }
    
}

@media screen and (min-width: 1550px) {
    .dcs__articlesection h2 {
        font-size: 4rem;
        margin-bottom: 5rem;
    }
}