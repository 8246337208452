.dcs__homepage {
    scroll-snap-type: y proximity;
    overflow-y: auto;
    overflow-x: hidden;
    perspective: 10px;
    /* height: 100vh; */
}

.dcs__ctasection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30% 0 30% 0;

    scroll-snap-align: center;
}

.dcs__ctasection .dcs__booknowbutton {
    align-items: center;
    scale: 1;
}

.dcs__ctasection .dcs__booknowbutton h3 {
    color: var(--c-text);
    font-weight: 400;

    font-size: 1.2rem;
    line-height: 3rem;
}

@media screen and (min-width: 1000px) {
    .dcs__ctasection {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20% 0 20% 0;
    }

    .dcs__ctasection .dcs__booknowbutton {
        scale: 1.75;
    }
}