.dcs__mediumarticletile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
}

.dcs__mediumarticletile-line {
    height: 1px;
    width: 90%;
    background: var(--c-text);
    margin-bottom: 1rem;
}
    .dcs__mediumarticletile-container {
        display: flex;
        align-items: center;
        width: 90%;
        gap: 10px;
    }
    
    .dcs__mediumarticletile-container img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        object-position: 50% 0;
        flex: 1;
    }
    
    .dcs__mediumarticletile-container_content {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        font-family: var(--font-family);
        color: var(--c-text);

        flex: 1.2;
    }
    
    .dcs__mediumarticletile-container_content h5 {
        font-weight: 500;
        font-size: .8rem;
    }
    .dcs__mediumarticletile-container_content a {
        text-decoration: none;
        color: var(--c-text);
    }
    .dcs__mediumarticletile-container_content a:hover {
        opacity: .9;
    }
    .dcs__mediumarticletile-container_content a:active {
        opacity: .7;
    }

    .dcs__mediumarticletile-container_content h2 {
        width: 100%;
        font-size: 1.1rem;
        align-self: flex-start;
        margin: 5px 0 5px 0;
    }
   
    .dcs__mediumarticletile-container_content p {
        text-align: justify;
        letter-spacing: .5px;
        line-height: 1.2rem;
    }
    .dcs__mediumarticletile-container_content p span {
        display: none;
    }
    
    
    @media screen and (min-width: 650px) {
        .dcs__mediumarticletile-container {
            width: 80%;
        }

        .dcs__mediumarticletile-line {
            width: 80%;
        }
    
        .dcs__mediumarticletile-container img {
            height: 200px;
        }
        .dcs__mediumarticletile-container_content {
            flex: 1;
        }
        .dcs__mediumarticletile-container_content h5 {
            font-size: 1rem;
        }
        .dcs__mediumarticletile-container_content-line1 {
            display: block;
            margin: .5rem 0 .5em 0;
        }
        .dcs__mediumarticletile-container_content h2 {
            width: 100%;
            font-size: 2.5rem;
            line-height: 2.7rem;
        }
        .dcs__mediumarticletile-container_content p {
            font-size: 1.25rem;
            line-height: 1.5rem;
            align-items: flex-end;
        }
    }
    
    @media screen and (min-width: 1050px) {
        .dcs__mediumarticletile-container img {
            height: 300px;
            flex: 1;
        }
        .dcs__mediumarticletile-container_content {
            flex: 1.5;
        }
        .dcs__mediumarticletile-container_content h5 {
            font-size: 1.25rem;
        }
        .dcs__mediumarticletile-container_content-line1 {
            display: block;
            margin: .5rem 0 .5em 0;
        }
        .dcs__mediumarticletile-container_content h2 {
            width: 100%;
            font-size: 2.5rem;
            line-height: 2.7rem;
        }
        .dcs__mediumarticletile-container_content p {
            font-size: 1.25rem;
            line-height: 1.5rem;
            align-items: flex-end;
            margin: 20px 0 20px 0;
        }
        .dcs__mediumarticletile-container_content p span {
            display: block;
        }
    }

    @media screen and (min-width: 1500px) {
        .dcs__mediumarticletile-container_content p {
            line-height: 1.9rem;
            
        }
    }
  