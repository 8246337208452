/* Import Google Fonts, Manrope and Oswald */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Oswald:wght@300;400;500;600&display=swap');

/* Quick access variables for styling */
:root {
  --font-family-headings: 'Oswald', sans-serif;
  --font-family: 'Manrope', sans-serif;

  --c-red: #B91919;
  --c-deep-red: #350000;
  --c-headings: #303030;
  --c-text: #303030;
  --c-light-text: #787878;
  --c-white-text-headings: white;
  --c-white-text: #D9D9D9;
  --c-social-logo: #303030;
}