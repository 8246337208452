.dcs__booknowbutton {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.dcs__booknowbutton h3 {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: var(--c-white-text-headings);
    margin-bottom: .5rem;
}

.dcs__booknowbutton-container {
    display: flex;
    background: var(--c-red);
    width: 200px;
    padding: .7rem;
    border-radius: 86px;

    align-items: center;
    justify-content: center;
    justify-self: end;
    cursor: pointer;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.dcs__booknowbutton-container:hover,
.dcs__booknowbutton-container button:hover {
    background: rgb(175, 8, 8);
    cursor: pointer;
}
.dcs__booknowbutton-container:active {
    opacity: .8;
}

.dcs__booknowbutton-container button {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.8rem;

    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    color: var(--c-white-text-headings);
    /* text-decoration: none; */
}


